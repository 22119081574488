<template>
  <div>
    <div
      class="hideOnPrint"
      style="width: 270px;float:left; padding:10px;margin:10px;"
    >
      <button
        v-on:click="addEvent()"
        class="btn btn-secondary btn-sm btn-block mb-2"
      >
        <em class="icon ni ni-plus-medi mr-1"></em> Add Event
      </button>
      <vc-calendar
        ref="calendar"
        v-model="selectedDate"
        @dayclick="sc_dayclick"
        style=""
      />
      <h5 class="mt-3">Selected Date</h5>
      <p>{{ $moment(selectedDate).format("dddd DD MMMM YYYY") }}</p>
    </div>
    <div>
      <div style="width:100%; height:40px; padding:5px;" class="hideOnPrint">
        <a-select default-value="month" style="width: 120px" v-model="viewType">
          <a-select-option value="week">
            Week
          </a-select-option>
          <a-select-option value="month">
            Month
          </a-select-option>
          <a-select-option value="year">
            Year
          </a-select-option>
          <a-select-option value="print">
            Month Day List
          </a-select-option>
        </a-select>
        <div class="float-right">
          <a-button class="btn btn-sm btn-dark mr-1" @click="resetView()"
            ><em class="icon ni ni-reload"></em
          ></a-button>
          <a-button
            v-if="!isMaximised && viewType != 'print'"
            class="btn btn-sm btn-dark mr-1"
            @click="togglePrintView()"
            >Expand</a-button
          >
          <a-button
            v-if="isMaximised && viewType != 'print'"
            class="btn btn-sm btn-dark mr-1"
            @click="togglePrintView()"
            >Condense</a-button
          >
          <a-button class="btn btn-sm btn-dark mr-1" @click="printAsList()"
            >Print As List</a-button
          >
          <!-- <a-button class="btn btn-sm btn-dark" @click="print()">Print</a-button> -->
          <a-select
            mode="multiple"
            :default-value="['Holidays', 'Meetings']"
            style="width: 300px"
            placeholder="Displayed Events"
          >
            <a-select-option value="Holidays">Holidays</a-select-option>
            <a-select-option value="Meetings">Meetings</a-select-option>
          </a-select>
        </div>
      </div>
      <simpleCalendar
        :simpleEventDates="simpleEventDates"
        v-if="viewType === 'print'"
      />
      <div v-if="viewType != 'print'" class="printPadd10">
        <div id="calContainer" class="printme">
          <calendar-view
            :events="events"
            :period-changed-callback="periodChanged"
            :displayPeriodUom="viewType"
            :show-date="selectedDate"
            @click-date="bc_dayclick"
            @click-event="bc_eventclick"
            class="theme-default holiday-us-traditional holiday-us-official"
          >
            <calendar-view-header
              slot="header"
              slot-scope="t"
              :header-props="t.headerProps"
              @input="setShowDate"
            />
          </calendar-view>
        </div>
      </div>
    </div>
    <a-drawer
      title="Calendar Event"
      :placement="'right'"
      :closable="true"
      :visible="displayEvent"
      @close="closeEvent"
      :width="650"
    >
      <calendarEvent
        @updated="eventUpdated"
        :selectedEvent="selectedEvent"
      ></calendarEvent>
    </a-drawer>
  </div>
</template>

<script>
import calendarEvent from "@/components/calendar/calendarevent";
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
import simpleCalendar from "@/components/calendar/simpleCalendar.vue";
// The next two lines are processed by webpack. If you're using the component without webpack compilation,
// you should just create <link> elements for these. Both are optional, you can create your own theme if you prefer.
require("vue-simple-calendar/static/css/default.css");
// require("vue-simple-calendar/static/css/holidays-us.css")

export default {
  // KEY bc_=Big Calendar sc_ = small calendar.
  name: "calendarApp",
  computed: {
    cssVars() {
      return {
        "--layout": this.layoutOrientation,
      };
    },
  },
  data: function() {
    return {
      layoutOrientation: "landscape",
      simpleEventDates: {
        monthName: "",
        events: [],
      },
      isMaximised: false,
      filters: { startDate: null, endDate: null, eventTypeIds: [1, 2] },
      events: [],
      // events: [{
      // 	id: "1",
      // 	startDate: "2021-03-10",
      //     endDate: "2021-03-10",
      // },
      // {
      // 	id: "2",
      // 	startDate: '2021-03-18',
      //     title: "Something",
      // },
      // {
      // 	id: "3",
      // 	startDate: '2021-03-03',
      // 	title: "Single-day item with a long title",
      // },],
      viewType: "month",
      selectedDate: new Date(),
      displayEvent: false,
      selectedEvent: null,
    };
  },
  components: {
    CalendarView,
    CalendarViewHeader,
    calendarEvent,
    simpleCalendar,
  },
  created() {},
  methods: {
    togglePrintView() {
      if (this.isMaximised === true) {
        this.removePrintHeights();
      } else {
        this.addPrintHeights();
      }
    },
    getLength(elementArray) {
      if (elementArray == 0 || !elementArray.length) {
        return 0;
      } else {
        return elementArray.length;
      }
    },
    addPrintHeights() {
      let classesNodeList = document.querySelectorAll(".cv-week");
      let totalHeights = 1;
      Array.prototype.map.call(classesNodeList, function(element) {
        //alert(this.getLength(element.querySelectorAll('.offset3') || []) + this.getLength(element.querySelectorAll('.offset0, .span4') || []) + this.getLength(element.querySelectorAll('.offset1 .span3, .offset1 .span4, .offset1 .span5') || []) + this.getLength(element.querySelectorAll('.offset2, .span2') || []))
        let ColumnArr = [
          element.querySelectorAll(".offset0").length || 0,
          element.querySelectorAll(
            ".offset1,.offset0.span2,.offset0.span3,.offset0.span4,.offset0.span5,.offset0.span6,.offset0.span7"
          ).length || 0,
          element.querySelectorAll(
            ".offset2,.offset1.span2,.offset1.span3,.offset1.span4,.offset1.span5,.offset1.span6,.offset0.span3,.offset0.span4,.offset0.span5,.offset0.span6,.offset0.span7"
          ).length || 0,
          element.querySelectorAll(
            ".offset3,.offset2.span2,.offset2.span3,.offset2.span4,.offset2.span5,.offset1.span3,.offset1.span4,.offset1.span5,.offset1.span6,.offset0.span4,.offset0.span5,.offset0.span6,.offset0.span7"
          ).length || 0,
          element.querySelectorAll(
            ".offset4,.offset3.span2,.offset3.span3,.offset3.span4,.offset2.span3,.offset2.span4,.offset2.span5,.offset1.span4,.offset1.span5,.offset1.span6,.offset0.span5,.offset0.span6,.offset0.span7"
          ).length || 0,
          //,(element.querySelectorAll('.offset1.span5,.offset1.span6,.offset0.span6.offset0.span7').length || 0)
          element.querySelectorAll(
            ".offset5,.offset4.span2,.offset4.span3,.offset3.span3,.offset3.span4,.offset2.span4,.offset2.span5,.offset1.span5,.offset1.span6,.offset0.span6,.offset0.span7"
          ).length || 0,
          element.querySelectorAll(
            ".offset6,.offset0.span7,.offset1.span6,.offset2.span5,.offset3.span4,.offset4.span3,.offset5.span2"
          ).length || 0,
        ];
        // alert(ColumnArr + 'highest - ' + Math.max(...ColumnArr) + ' Totol- ' + ColumnArr.reduce((a, b) => a + b, 0))

        totalHeights += Math.max(...ColumnArr);
        // add classes to each week.
        element.classList.add("maxCalCellHeight" + Math.max(...ColumnArr));
        //alert(Math.max(...ColumnArr))
        //alert(ColumnArr.reduce((a, b) => a + b, 0))
      });
      // set the total height of the container.
      if (totalHeights <= 20) {
        this.isMaximised = false;
        // document.getElementById("calContainer").style.height = "80vh"
      } else {
        this.isMaximised = true;
        document.getElementById("calContainer").style.height =
          totalHeights * 22 + 20 + "px";
      }
    },
    removePrintHeights() {
      var el = document.querySelector('div[class*="maxCalCellHeight"]');
      if (el) {
        for (let i = el.classList.length - 1; i >= 0; i--) {
          const className = el.classList[i];
          if (className.startsWith("maxCalCellHeight")) {
            el.classList.remove(className);
          }
        }
        // reset the viewheight
        document.getElementById("calContainer").style.height = "80vh";
      }
      this.isMaximised = false;
    },
    resetView() {
      this.viewType = "month";
      this.removePrintHeights();
    },
    printAsList() {
      this.viewType = "print";
      this.$nextTick(() => {
        window.print();
      });
    },
    print() {
      //var newstr = document.getElementsByClassName('printme')[0].innerHTML
      //document.body.innerHTML = newstr
      window.print();
      // Reload the page to refresh the data
      //window.location.reload()
    },
    thisMonth(d, h, m) {
      const t = new Date();
      return new Date(t.getFullYear(), t.getMonth(), d, h || 0, m || 0);
    },
    periodChanged(range) {
      this.filters.startDate = range.displayFirstDate;
      this.filters.endDate = range.displayLastDate;
      this.getEvents();

      // range, eventSource) {
      // Demo does nothing with this information, just including the method to demonstrate how
      // you can listen for changes to the displayed range and react to them (by loading items, etc.)
      //console.log(eventSource)
      //console.log(range)
    },
    getEvents() {
      this.$http
        .post("/events/Get_Events/", this.filters)
        .then((response) => {
          this.events = response.data;

          this.$nextTick(() => {
            if (this.isMaximised) {
              this.addPrintHeights();
            } else {
              this.removePrintHeights();
            }
          });

          this.generateSimpleData();
        })
        .catch(() => {
          this.$message.error("There has been an error");
        });
    },
    eventUpdated() {
      this.getEvents();
      this.displayEvent = false;
    },
    resetEvent() {
      this.selectedEvent = {
        id: null,
        name: "",
        startDate: null,
        endDate: null,
        eventTypeId: 1,
        description: "",
      };
    },
    addEvent(startDate, endDate) {
      this.resetEvent();
      this.selectedEvent.startDate = startDate;
      this.selectedEvent.endDate = endDate;
      this.displayEvent = true;
    },
    closeEvent() {
      this.displayEvent = false;
    },
    openEvent() {
      this.displayEvent = true;
    },
    setShowDate(d) {
      this.selectedDate = d;
    },
    sc_dayclick(day) {
      this.selectedDate = new Date(day.id);
    },
    bc_dayclick(d) {
      let smallCalendar = this.$refs.calendar;
      let newDate = new Date(d);
      newDate.setHours(9);
      this.selectedDate = newDate;
      smallCalendar.focusDate(newDate);
      this.addEvent(
        this.$moment(newDate),
        this.$moment(newDate).add(1, "hours")
      );
      // this.smallCalendar.move(d.toISOString().substring(0,10))
    },
    bc_eventclick(event) {
      this.selectedEvent = event.originalEvent;
      this.displayEvent = true;
    },
    // new calendar
    generateSimpleData() {
      // call this in getEvents
      this.simpleEventDates.monthName = new this.$moment(this.filters.startDate)
        .add("days", 10)
        .format("MMMM");
      this.simpleEventDates.events = this.getAllDatesInAmonth(
        this.$moment(this.filters.startDate)
          .add("days", 10)
          .startOf("month")
      );
      this.events.forEach((event) => {
        this.addEventToSimpleDates(event);
      });
    },
    addEventToSimpleDates(event) {
      let currentDay = new this.$moment(event.startDate);
      let lastDay = new this.$moment(event.endDate);
      while (currentDay <= lastDay) {
        // add a day.
        this.simpleEventDates.events.forEach((evt) => {
          if (
            String(evt.date.startOf("day")) ===
            String(currentDay.startOf("day"))
          ) {
            evt.events.push(event.title.replace(" - Holiday", ""));
          }
        });
        currentDay.add("days", 1);
      }
    },
    getAllDatesInAmonth(year) {
      var days = [];
      let month = year.format("MM");
      let currentDate = year;

      while (month == currentDate.format("MM")) {
        days.push({ date: this.$moment(currentDate), events: [] });
        currentDate == currentDate.add("days", 1);
      }
      console.log(days);
      return days;
    },
  },
};
</script>

<style>
@page {
  size: var(--orientation);
}
/* @page { size: landscape; } */

/* + 16px */
.maxCalCellHeight1 {
  max-height: 40px;
}
.maxCalCellHeight2 {
  max-height: 60px;
}
.maxCalCellHeight3 {
  max-height: 80px;
}
.maxCalCellHeight4 {
  max-height: 100px;
}

.maxCalCellHeight5 {
  max-height: 120x;
}
.maxCalCellHeight6 {
  max-height: 140px;
}
.maxCalCellHeight7 {
  max-height: 160px;
}
.maxCalCellHeight8 {
  max-height: 180px;
}
.maxCalCellHeight9 {
  max-height: 200px;
}
.maxCalCellHeight10 {
  max-height: 220px;
}
.maxCalCellHeight11 {
  max-height: 240px;
}
.maxCalCellHeight12 {
  max-height: 260px;
}
.maxCalCellHeight13 {
  max-height: 280px;
}
.maxCalCellHeight14 {
  max-height: 300px;
}
.maxCalCellHeight15 {
  max-height: 320px;
}
.maxCalCellHeight16 {
  max-height: 340px;
}
.maxCalCellHeight17 {
  max-height: 360px;
}
.maxCalCellHeight18 {
  max-height: 380px;
}
.maxCalCellHeight19 {
  max-height: 400px;
}
.maxCalCellHeight20 {
  max-height: 420px;
}
.maxCalCellHeight22 {
  max-height: 440px;
}
.maxCalCellHeight23 {
  max-height: 460px;
}
.maxCalCellHeight24 {
  max-height: 480px;
}
.maxCalCellHeight25 {
  max-height: 500px;
}
.maxCalCellHeight26 {
  max-height: 520px;
}
.maxCalCellHeight27 {
  max-height: 540px;
}
.maxCalCellHeight28 {
  max-height: 560px;
}
.maxCalCellHeight29 {
  max-height: 580px;
}
.maxCalCellHeight30 {
  max-height: 600px;
}
.maxCalCellHeight31 {
  max-height: 620px;
}
.maxCalCellHeight32 {
  max-height: 692px;
}
.maxCalCellHeight33 {
  max-height: 713px;
}
.maxCalCellHeight34 {
  max-height: 680px;
}
.maxCalCellHeight35 {
  max-height: 700px;
}

#calContainer {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  color: #2c3e50;
  height: 80vh;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

/* #calContainer{
        height:300vh !important;
    } */

.theme-default .cv-header,
.theme-default .cv-header-day {
  background-color: #fff;
}
.cv-header-day {
  background-color: #f0f0f0;
}

.cv-header .periodLabel {
  font-size: 1.5em;
}

.cv-header button {
  color: #fff !important;
  background-color: #364a63;
  border-color: #364a63;
}

.cv-header button:hover {
  color: #fff;
  background-color: #243142;
  border-color: #202b3a;
}

.cv-header button:disabled {
  color: #ccc;
  background-color: #f7f7f7;
}

/* Grid */

.cv-day.past {
  background-color: #fafafa;
}

.theme-default .cv-day.outsideOfMonth {
  background-color: #bfbfbf;
}

.cv-day.today {
  background-color: #ffe;
}

/* Events */

.cv-event {
  border-color: #e0e0f0;
  border-radius: 0.5em;
  background-color: #e7e7ff;
  text-overflow: ellipsis;
}

.cv-event.purple {
  background-color: #f0e0ff;
  border-color: #e7d7f7;
}

.cv-event.orange {
  background-color: #ffe7d0;
  border-color: #f7e0c7;
}

.cv-event.continued::before,
.cv-event.toBeContinued::after {
  content: " \21e2 ";
  color: #999;
}

.cv-event.toBeContinued {
  border-right-style: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.cv-event.isHovered.hasUrl {
  text-decoration: underline;
}

.cv-event.continued {
  border-left-style: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* Event Times */

.cv-event .startTime,
.cv-event .endTime {
  font-weight: bold;
  color: #666;
}

/* Drag and drop */

.cv-day.draghover {
  box-shadow: inset 0 0 0.2em 0.2em yellow;
}
@media print {
  .printme {
    display: flex;
  }
  .cv-event {
    font-size: 12px !important;
    background-color: rgb(245 246 250) !important;
    border: 1px solid #000 !important;
    color: rgb(0 0 0) !important;
    font-weight: 600 !important;
  }
  .printPadd10 {
    padding: 10px;
  }
}
</style>
