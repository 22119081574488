<template>
    <div class="mr-1">
        <div class="calendar">
            <!-- <img class="logo-dark logo-img float-right" src="@/assets/images/logos/logo-lightbg1.svg" alt="logo-dark"> Thinking we might want to put this at the top rather than have the header bar showing?-->
            <h6 style="padding: 10px; margin-top: 8px;">
                {{ $moment(simpleEventDatesTest.events[10].date).format('MMMM') }}
            </h6>

            <ol class="day-names">
                <li v-for="date in simpleEventDatesTest.events.slice(0, 7)" :key="date.date">
                    {{ $moment(date.date).format('dddd') }}
                </li>
            </ol>
            <table>
                <tbody>
                    <tr>
                        <td valign="top" class="simpleCalTableCell" :class="$moment(date.date).format('dddd') === 'Saturday' || $moment(date.date).format('dddd') === 'Sunday' ? 'blackDay' : ''" v-for="date in simpleEventDatesTest.events.slice(0, 7)" :key="date.date">
                            <div style="">
                                <div class="simpleCalTableCellDate">
                                    {{ $moment(date.date).format('D') }}
                                </div>
                                <div v-for="event in date.events" :key="event.title" class="event all-day">
                                    {{ event }}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td valign="top" class="simpleCalTableCell" :class="$moment(date.date).format('dddd') === 'Saturday' || $moment(date.date).format('dddd') === 'Sunday' ? 'blackDay' : ''" v-for="date in simpleEventDatesTest.events.slice(7, 14)" :key="date.date">
                            <div style="">
                                <div class="simpleCalTableCellDate">
                                    {{ $moment(date.date).format('D') }}
                                </div>
                                <div v-for="event in date.events" :key="event.title" class="event all-day">
                                    {{ event }}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td valign="top" class="simpleCalTableCell" :class="$moment(date.date).format('dddd') === 'Saturday' || $moment(date.date).format('dddd') === 'Sunday' ? 'blackDay' : ''" v-for="date in simpleEventDatesTest.events.slice(14, 21)" :key="date.date">
                            <div style="">
                                <div class="simpleCalTableCellDate">
                                    {{ $moment(date.date).format('D') }}
                                </div>
                                <div v-for="event in date.events" :key="event.title" class="event all-day">
                                    {{ event }}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td valign="top" class="simpleCalTableCell" :class="$moment(date.date).format('dddd') === 'Saturday' || $moment(date.date).format('dddd') === 'Sunday' ? 'blackDay' : ''" v-for="date in simpleEventDatesTest.events.slice(21, 28)" :key="date.date">
                            <div style="">
                                <div class="simpleCalTableCellDate">
                                    {{ $moment(date.date).format('D') }}
                                </div>
                                <div v-for="event in date.events" :key="event.title" class="event all-day">
                                    {{ event }}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td valign="top" class="simpleCalTableCell" :class="$moment(date.date).format('dddd') === 'Saturday' || $moment(date.date).format('dddd') === 'Sunday' ? 'blackDay' : ''" v-for="date in simpleEventDatesTest.events.slice(28, 35)" :key="date.date">
                            <div style="">
                                <div class="simpleCalTableCellDate">
                                    {{ $moment(date.date).format('D') }}
                                </div>
                                <div v-for="event in date.events" :key="event.title" class="event all-day">
                                    {{ event }}
                                </div>
                            </div>
                        </td>
                        <td v-if="simpleEventDatesTest.events.length < 29" valign="top" class="simpleCalTableCell">
                            <div class="date"></div>
                        </td>
                        <td v-if="simpleEventDatesTest.events.length < 30" valign="top" class="simpleCalTableCell">
                            <div class="date"></div>
                        </td>
                        <td v-if="simpleEventDatesTest.events.length < 31" valign="top" class="simpleCalTableCell">
                            <div class="date"></div>
                        </td>
                        <td v-if="simpleEventDatesTest.events.length < 32" valign="top" class="simpleCalTableCell">
                            <div class="date"></div>
                        </td>
                        <td v-if="simpleEventDatesTest.events.length < 33" valign="top" class="simpleCalTableCell">
                            <div class="date"></div>
                        </td>
                        <td v-if="simpleEventDatesTest.events.length < 34" valign="top" class="simpleCalTableCell">
                            <div class="date"></div>
                        </td>
                        <td v-if="simpleEventDatesTest.events.length < 35" valign="top" class="simpleCalTableCell">
                            <div class="date"></div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <!-- <ol class="days">
						<li  v-for="date in simpleEventDates.events" :key="date.date">
								<div class="date">
										{{$moment(date.date).format('d')}}
								</div>
								<div v-for="event in date.events" :key="event.title" class="event all-day">
										{{event}}
								</div>
						</li>
				</ol> -->
        </div>
    </div>
</template>

<script>
export default {
    props: {
        simpleEventDates: Object
    },
    data() {
        return {
            simpleEventDatesTest: {}
        }
    },
    created() {
        this.sortDates()
    },
    methods: {
        sortDates() {
            this.simpleEventDatesTest = JSON.parse(JSON.stringify(this.simpleEventDates))
            if (this.$moment(this.simpleEventDatesTest.events[0].date).format('dddd') === 'Tuesday') {
                this.simpleEventDatesTest.events.splice(0, 0, { date: this.$moment(this.simpleEventDatesTest.events[0].date).add('days', -1) })
            }
            if (this.$moment(this.simpleEventDatesTest.events[0].date).format('dddd') === 'Wednesday') {
                this.simpleEventDatesTest.events.splice(0, 0, { date: this.$moment(this.simpleEventDatesTest.events[0].date).add('days', -2) })
                this.simpleEventDatesTest.events.splice(1, 0, { date: this.$moment(this.simpleEventDatesTest.events[0].date).add('days', 1) })
            }
            if (this.$moment(this.simpleEventDatesTest.events[0].date).format('dddd') === 'Thursday') {
                this.simpleEventDatesTest.events.splice(0, 0, { date: this.$moment(this.simpleEventDatesTest.events[0].date).add('days', -3) })
                this.simpleEventDatesTest.events.splice(1, 0, { date: this.$moment(this.simpleEventDatesTest.events[0].date).add('days', 1) })
                this.simpleEventDatesTest.events.splice(2, 0, { date: this.$moment(this.simpleEventDatesTest.events[0].date).add('days', 2) })
            }
            if (this.$moment(this.simpleEventDatesTest.events[0].date).format('dddd') === 'Friday') {
                this.simpleEventDatesTest.events.splice(0, 0, { date: this.$moment(this.simpleEventDatesTest.events[0].date).add('days', -4) })
                this.simpleEventDatesTest.events.splice(1, 0, { date: this.$moment(this.simpleEventDatesTest.events[0].date).add('days', 1) })
                this.simpleEventDatesTest.events.splice(2, 0, { date: this.$moment(this.simpleEventDatesTest.events[0].date).add('days', 2) })
                this.simpleEventDatesTest.events.splice(3, 0, { date: this.$moment(this.simpleEventDatesTest.events[0].date).add('days', 3) })
            }
            if (this.$moment(this.simpleEventDatesTest.events[0].date).format('dddd') === 'Saturday') {
                this.simpleEventDatesTest.events.splice(0, 0, { date: this.$moment(this.simpleEventDatesTest.events[0].date).add('days', -5) })
                this.simpleEventDatesTest.events.splice(1, 0, { date: this.$moment(this.simpleEventDatesTest.events[0].date).add('days', 1) })
                this.simpleEventDatesTest.events.splice(2, 0, { date: this.$moment(this.simpleEventDatesTest.events[0].date).add('days', 2) })
                this.simpleEventDatesTest.events.splice(3, 0, { date: this.$moment(this.simpleEventDatesTest.events[0].date).add('days', 3) })
                this.simpleEventDatesTest.events.splice(4, 0, { date: this.$moment(this.simpleEventDatesTest.events[0].date).add('days', 4) })
            }
            if (this.$moment(this.simpleEventDatesTest.events[0].date).format('dddd') === 'Sunday') {
                this.simpleEventDatesTest.events.splice(0, 0, { date: this.$moment(this.simpleEventDatesTest.events[0].date).add('days', -6) })
                this.simpleEventDatesTest.events.splice(1, 0, { date: this.$moment(this.simpleEventDatesTest.events[0].date).add('days', 1) })
                this.simpleEventDatesTest.events.splice(2, 0, { date: this.$moment(this.simpleEventDatesTest.events[0].date).add('days', 2) })
                this.simpleEventDatesTest.events.splice(3, 0, { date: this.$moment(this.simpleEventDatesTest.events[0].date).add('days', 3) })
                this.simpleEventDatesTest.events.splice(4, 0, { date: this.$moment(this.simpleEventDatesTest.events[0].date).add('days', 4) })
                this.simpleEventDatesTest.events.splice(5, 0, { date: this.$moment(this.simpleEventDatesTest.events[0].date).add('days', 5) })
            }
        }
    }
}
</script>

<style>
/* table,
tr,
td,
div {
		page-break-inside: avoid;
} */
.simpleCalTableCell {
    width: 90px;
    padding: 5px;
}
.blackDay {
    background-color: black;
    color: black;
}

.calendar {
    position: relative;
    overflow: hidden;
    max-width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    background: #fff;
    box-shadow: 0.25rem 0.25rem 2rem var(--shade-color), -1rem -1rem 2rem var(--bg-color);
}
.calendar ol {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.calendar ol li {
    width: calc(100% / 7);
    padding: 1rem;
    background: linear-gradient(-145deg, transparent, rgba(0, 0, 0, 0.025));
    break-inside: avoid;
}
.calendar .days {
    flex-grow: 1;
}
.calendar .day-names {
    background: linear-gradient(70deg, #c4e1ed, #bad3dd);
    color: var(--headline-color);
    flex-shrink: 0;
    flex-grow: 0;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    line-height: 1;
}
.calendar .date {
    margin-bottom: 0.25em;
    font-size: 14px;
    font-weight: bold;
}
.calendar .event.all-day {
    font-size: 14px !important;
    font-weight: bold;
}
.calendar .event.clear {
    visibility: hidden;
}
@media print {
    .hideOnPrint {
        display: none;
    }
    .nk-header {
        display: none;
    }
    .nk-wrap {
        padding-top: 0px !important;
    }
}
</style>
