<template>
  <div>
        <scheduler></scheduler>
  </div>
</template>

<script>
	import scheduler from '@/components/calendar/calendar'
export default {
  methods:{
    getView (){ this.$http.get('/ViewAccess/mycalendar')
        .then(() => { 
        })
        .catch(() => { 
        })},
  },
  created(){
    this.getView()
  },
components: {
  scheduler
},
}
</script>
<style>
  
</style>
