<template>
  <div class="col-12" style="padding:20px;">
    <a-form-model :model="calendarEvent">
        <a-form-model-item label="Name of Event" ref="title" prop="title">
            <a-input v-model="calendarEvent.title" :class="{'is-error' : $v.calendarEvent.title.$invalid }" />
        </a-form-model-item>
        <a-form-model-item label="Date">
            <a-date-picker v-model="calendarEvent.startDate" :class="{'is-error' : $v.calendarEvent.startDate.$invalid }" :disabled-date="disabledStartDate" show-time format="DD-MM-YYYY HH:mm" placeholder="Start" @openChange="handleStartOpenChange"/>
            <span><a-icon type="double-right" style="padding: 5px; fontSize:17px;"></a-icon></span>
            <a-date-picker v-model="calendarEvent.endDate" :class="{'is-error' : $v.calendarEvent.endDate.$invalid }" :disabled-date="disabledEndDate" show-time format="DD-MM-YYYY HH:mm" placeholder="End" :open="endOpen" @openChange="handleEndOpenChange"/>
            <button v-on:click="setAllDayEvent()" class="btn btn-sm btn-dark float-right">All Day</button>
        </a-form-model-item>
        <a-form-model-item label="Event Type">
            <a-select v-model="calendarEvent.eventTypeId" :default-value="eventTypes[0]">
                <a-select-option v-for="holidayType in eventTypes" v-bind:key="holidayType.id">
                    {{ holidayType.text }}
                </a-select-option>
            </a-select>
        </a-form-model-item>
        <a-form-model-item>
            <a-button type="success" class="float-right" @click="saveEvent">
                Save
            </a-button>
        </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
    props: {
        selectedEvent: {
        type: Object
        },
    },
    validations: {
        calendarEvent: {
            title: {
                required
            },
            startDate: {
                required
            },
            endDate: {
                required
            }
        }
    },
    data() {
        return { 
            calendarEvent: {
                eventId: null,
                name: '',
                startDate: null,
                endDate: null,
                eventTypeId: 1,
                description: '',
            },
            eventTypes: [],
            holidayTypes: [],
            endOpen: false,
        }
    },
    created () {
        this.getLists()
        this.calendarEvent = this.selectedEvent
        // if (this.selectedEventId != null) {
        //     this.getEvent()
        //     this.getLists()
        // } else {
        
        //}
    },
     watch: {
        selectedEvent() {
            this.calendarEvent = this.selectedEvent
            if (this.selectedEvent.id){
                 this.getEvent()
            }
        }
    },
    methods: {
        setAllDayEvent () {
            this.calendarEvent.endDate = this.$moment(this.calendarEvent.endDate).endOf('day')
            this.calendarEvent.startDate = this.$moment(this.calendarEvent.startDate).startOf('day')
        },
        saveEvent() {
            if(this.calendarEvent.id != null) {
                if (this.$v.calendarEvent.$invalid) {
                    this.$message.error('Please check for any form errors.')
                } else {
                    this.updateCalendarEvent()
                }
            }
            else {
                if (this.$v.calendarEvent.$invalid) {
                    this.$message.error('Please check for any form errors.')
                } else {
                    this.addCalendarEvent()
                }
            }
        },
        addCalendarEvent() {
            this.$http.post('/events/Add_EventDate', this.calendarEvent)
            .then(() => {
                this.$emit('updated')
                this.$message.success('Event Added')
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        updateCalendarEvent() {
            this.calendarEvent.eventId = this.selectedEventId
            this.$http.post('/events/Update_EventDate', this.calendarEvent)
            .then(() => {
                this.$emit('updated')
                this.$message.success('Event Updated')
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        getLists(){
            // this.$http.get('/lists/Get_List/holidayTypes')
            // .then((response) => {
            //     this.holidayTypes = response.data
            // })
            // .catch(() => {
            // this.$message.error('There has been an error')
            // })
            this.$http.get('/lists/Get_List/eventTypes')
            .then((response) => {
                this.eventTypes = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        getEvent(){
            this.$http.get('/events/Get_EventDate/' + this.selectedEvent.id)
            .then((response) => {
                this.calendarEvent = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        disabledStartDate(startDate) {
            const endDate = this.calendarEvent.endDate
            if (!startDate || !endDate) {
                return false;
            }
            return startDate.valueOf() > endDate.valueOf();
            },
        disabledEndDate(endDate) {
            const startDate = this.calendarEvent.startDate
            if (!endDate || !startDate) {
                return false;
            }
            return startDate.valueOf() >= endDate.valueOf();
        },
        handleStartOpenChange(open) {
            if (!open) {
                this.endOpen = true;
            }
        },
        handleEndOpenChange(open) {
            this.endOpen = open;
        },
    },
   
}
</script>

<style>

</style>